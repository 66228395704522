import React from 'react'

const LockIcon: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 18, onClick, className, color = '#FFFFFF' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 2.25C11.07 2.25 12.75 3.92 12.75 6L12.74 7.5L13.07 7.5C13.74 7.5 13.98 7.56 14.23 7.7C14.47 
          7.83 14.66 8.02 14.79 8.26C14.93 8.51 15 8.75 15 9.42L15 13.82C15 14.49 14.93 14.73 14.79 14.98C14.66 
          15.22 14.47 15.41 14.23 15.54C13.98 15.68 13.74 15.75 13.07 15.75L4.92 15.75C4.25 15.75 4.01 15.68 3.76 
          15.54C3.52 15.41 3.33 15.22 3.2 14.98C3.06 14.73 3 14.49 3 13.82L3 9.42C3 8.75 3.06 8.51 3.2 8.26C3.33 
          8.02 3.52 7.83 3.76 7.7C4.01 7.56 4.25 7.5 4.92 7.5L5.24 7.5L5.25 6C5.25 3.92 6.92 2.25 9 2.25ZM9 3.75C7.75 
          3.75 6.75 4.75 6.75 6L6.74 7.5L11.24 7.5L11.25 6C11.25 4.75 10.24 3.75 9 3.75Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default LockIcon
