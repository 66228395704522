export const MAIN = '/'
export const ABOUT = '/about'
export const NEWS = '/news'
export const NEWS_DETAIL = '/news/:newsId'
export const COMPETENCIES = '/competencies'
export const COMPETENCY = '/competencies/:competenceId'
export const COMPETENCY_FORUM = '/competencies/:competenceId/forum/'
export const COMPETENCY_TOPIC = '/competencies/:competenceId/forum/:topicId(\\d+)'
export const COMPETENCY_DOCS = '/competencies/:competenceId/docs/:dirId(\\d*)'
export const EVENTS = '/events'
export const EVENTS_PERIOD = '/events/:period([past|future])'
export const EVENT = '/events/:eventId(\\d+)'
export const GALLERY = '/gallery'
export const GALLERY_ALBUM = '/gallery/album/:albumId'
export const CONTACTS = '/contacts'
export const RESET_PASSWORD = '/reset_password/:uid/:token'
export const SLUG = '/:slug'
export const ALL = '*'
export const CHAMPIONSHIPS = '/championships'
export const CHAMPIONSHIPS_ALL = '/championships/all'
export const CHAMPIONSHIP = '/championships/champ/:championshipId'
export const PARTICIPANTS = '/participants'
export const USER_PROFILE = '/user-profile'
export const FAQ = '/faq'
export const SURVEYS = '/surveys'
export const SURVEYS_PASSED = '/surveys/passed'
export const SURVEY = '/surveys/:surveyId(\\d+)/:attemptId(\\d+)'
export const SURVEY_COMPLETED = '/surveys/:surveyId(\\d+)/:attemptId(\\d+)/completed'

export const SURVEYS_ADMIN = '/surveysAdmin'
export const SURVEYS_GROUP = '/surveysAdmin/surveysGroups'
export const SURVEYS_SECTION = '/surveysAdmin/surveysSection'
export const SURVEYS_QUESTIONS = '/surveysAdmin/surveysQuestions'
export const SURVEYS_RESULTS = '/surveysAdmin/surveysResults'
export const SURVEYS_RESULTS_DETAIL = '/surveysAdmin/surveysResults/:surveyId'
export const SURVEYS_VIEW = '/surveysAdmin/surveysView/:surveyId/:mode'
export const SURVEYS_CONTROL = '/surveysAdmin/surveysControl'
export const SURVEY_EDIT = '/surveysAdmin/editSurvey/:surveyId'
export const GROUP_EDIT = '/surveysAdmin/editGroup/:groupId'
export const SECTION_EDIT = '/surveysAdmin/editSection/:sectionId'
export const QUESTION_VIEW = '/surveysAdmin/questionView/:questionId'

export const VOTING = '/voting'
export const VOTING_DETAIL = '/voting/:votingId'
export const VOTING_HISTORY = '/votingHistory/:votingId'
export const VOTING_EDIT = '/votingEdit/:votingId'

export const RATING = '/rating'
export const RATING_SETTINGS = '/rating/ratingSettings'
export const RATING_MODULE = '/rating/ratingSettings/:module'
export const RATING_PARTICIPANTS = '/rating/ratingParticipants'
export const RATING_RESULTS = '/rating/ratingResults'
export const RATING_RESULT_PARTICIPANTS = '/rating/ratingResults/:participants'
export const RATING_DOCUMENTS = '/rating/ratingDocuments'
