import { useEffect, useState } from 'react'

type TPercentAtProgressBar = (localName: string, loadingStart: boolean) => number

type TIterationResult = (percent: number) => void

const getPercentUpload = (localName: string, iterationResult: TIterationResult) => {
  const percentInterval = setInterval(() => {
    const localPercent = localStorage.getItem(localName)

    if (localPercent) {
      iterationResult(+localPercent)

      if (+localPercent === 100) {
        const clearTimeoutId = setTimeout(() => {
          clearInterval(percentInterval)
          localStorage.removeItem(localName)
          iterationResult(0)
          clearTimeout(clearTimeoutId)
        }, 1000)
      }
    }
  }, 100)
}

export const usePercentAtProgressBar: TPercentAtProgressBar = (localName, loadingStart) => {
  const [uploadPercent, setUploadPercent] = useState<number>(0)

  useEffect(() => {
    if (!loadingStart) return
    getPercentUpload(localName, setUploadPercent)
  }, [loadingStart, localName])

  return uploadPercent
}

export const setPercent = (loaded: number, total: number, localNameWrite: string) => {
  const progress = Math.round((loaded / total) * 100)
  localStorage.setItem(localNameWrite, String(progress))
}
