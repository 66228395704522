import React from 'react'

const UserIcon: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#6E7191' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 18L4 19C4 19.55 4.44 20 5 20L19 20C19.55 20 20 19.55 20 19L20 18C20 14.5 14.66 13.5 12 13.5C9.33 
          13.5 4 14.5 4 18ZM12 4C14.21 4 16 5.79 16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default UserIcon
