import React from 'react'

const UsersIcon: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 60, onClick, className, color = '#6E7191' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.5 31.25C48.23 31.25 58.75 33.39 58.75 41.96C58.75 45.71 57.79 46.25 55.88 46.25L40.65 
          46.25C41.05 45.02 41.25 43.59 41.25 41.96C41.25 37.65 39.67 34.29 37.07 31.8C39.02 31.4 40.94 
          31.25 42.5 31.25ZM20 31.25C25.73 31.25 36.25 33.39 36.25 41.96C36.25 45.71 35.29 46.25 33.38 
          46.25L6.61 46.25C4.7 46.25 3.75 45.71 3.75 41.96C3.75 33.39 14.26 31.25 20 31.25ZM20 12.5C24.14 
          12.5 27.5 15.85 27.5 20C27.5 24.14 24.14 27.5 20 27.5C15.85 27.5 12.5 24.14 12.5 20C12.5 15.85 
          15.85 12.5 20 12.5ZM42.5 12.5C46.64 12.5 50 15.85 50 20C50 24.14 46.64 27.5 42.5 27.5C38.35 27.5 
          35 24.14 35 20C35 15.85 38.35 12.5 42.5 12.5Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default UsersIcon
