import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Team, TeamRequest } from '../../api/model/models'
import { RootState } from '../store'
import { transformEvalTeamsData } from '../../utils/transformEvalTeamsData'
import { participantsSliceActions } from './participantsSlice'

interface TInitialState {
  loading: boolean
  ratingTeams: {
    data: Array<Team>
    tableData: Array<RatingParticipantsTableData>
  }
}

const initialState: TInitialState = {
  loading: false,
  ratingTeams: {
    data: [],
    tableData: [],
  },
}

const getRatingTeams = createAsyncThunk(
  'getRatingTeams',
  (props: { championshipId: string | number; competenceId: string | number }) => {
    return appAPI.skillsPassport.getTeams(props.championshipId, props.competenceId)
  },
)

const createRatingTeams = createAsyncThunk(
  'createRatingTeams',
  async (
    props: {
      championshipId: string | number
      competenceId: string | number
      body: TeamRequest
    },
    { dispatch },
  ) => {
    const { championshipId, competenceId, body } = props
    const createTeams = await appAPI.skillsPassport.createTeams(championshipId, competenceId, body)

    dispatch(ratingParticipantsSliceActions.getRatingTeams({ championshipId, competenceId }))
    dispatch(
      participantsSliceActions.fetchParticipants({
        championshipId: String(championshipId),
        status: 'approved',
        hasTeam: false,
      }),
    )

    return createTeams
  },
)

const unitingRatingTeams = createAsyncThunk(
  'unitingRatingTeams',
  async ({ teams }: Required<Pick<TeamRequest, 'teams'>>, { dispatch, getState }) => {
    const unitingTeams = await appAPI.skillsPassport.unitingTeams({ teams: teams })

    const state = getState() as RootState
    const championshipId = state.rating.championshipsSelectID
    const competenceId = state.rating.competenciesSelectID

    if (championshipId && competenceId)
      dispatch(ratingParticipantsSliceActions.getRatingTeams({ championshipId, competenceId }))

    return unitingTeams
  },
)

const unitingParticipantsFromOtherRatingTeams = createAsyncThunk(
  'unitingParticipantsFromOtherRatingTeams',
  async (props: { participantId: string | number; teamId: string | number | null }, { dispatch, getState }) => {
    const unitingParticipants = await appAPI.skillsPassport.unitingParticipantsFromOtherTeams(
      props.participantId,
      props.teamId,
    )

    const state = getState() as RootState
    const championshipId = state.rating.championshipsSelectID
    const competenceId = state.rating.competenciesSelectID

    if (championshipId && competenceId)
      dispatch(ratingParticipantsSliceActions.getRatingTeams({ championshipId, competenceId }))

    if (!props.teamId) {
      dispatch(
        participantsSliceActions.fetchParticipants({
          championshipId: String(championshipId),
          status: 'approved',
          hasTeam: false,
        }),
      )
    }

    return unitingParticipants
  },
)

const deleteFromRatingTable = createAsyncThunk(
  'deleteFromRatingTable',
  async (teamId: number, { dispatch, getState }) => {
    const deleteParticipants = await appAPI.skillsPassport.deleteFromTable(teamId)

    const state = getState() as RootState
    const championshipId = state.rating.championshipsSelectID
    const competenceId = state.rating.competenciesSelectID

    if (championshipId && competenceId) {
      dispatch(ratingParticipantsSliceActions.getRatingTeams({ championshipId, competenceId }))
      dispatch(
        participantsSliceActions.fetchParticipants({
          championshipId: String(championshipId),
          status: 'approved',
          hasTeam: false,
        }),
      )
    }

    return deleteParticipants
  },
)

const disbandRatingTeams = createAsyncThunk('disbandRatingTeams', async (teamId: number, { dispatch, getState }) => {
  const disbandParticipants = await appAPI.skillsPassport.disbandTeams(teamId)

  const state = getState() as RootState
  const championshipId = state.rating.championshipsSelectID
  const competenceId = state.rating.competenciesSelectID

  if (championshipId && competenceId)
    dispatch(ratingParticipantsSliceActions.getRatingTeams({ championshipId, competenceId }))

  return disbandParticipants
})

const ratingParticipantsSlice = createSlice({
  name: 'ratingParticipants',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRatingTeams.pending, (state) => {
        state.loading = true
      })
      .addCase(getRatingTeams.fulfilled, (state, { payload }) => {
        const transformResult = transformEvalTeamsData(payload.data)

        state.ratingTeams.data = payload.data
        state.ratingTeams.tableData = transformResult
        state.loading = false
      })
      .addCase(getRatingTeams.rejected, (state) => {
        state.loading = false
      })
      .addCase(createRatingTeams.pending, (state) => {
        state.loading = true
      })
      .addCase(createRatingTeams.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createRatingTeams.rejected, (state) => {
        state.loading = false
      })
      .addCase(unitingRatingTeams.pending, (state) => {
        state.loading = true
      })
      .addCase(unitingRatingTeams.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(unitingRatingTeams.rejected, (state) => {
        state.loading = false
      })
      .addCase(deleteFromRatingTable.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteFromRatingTable.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteFromRatingTable.rejected, (state) => {
        state.loading = false
      })
  },
})

export default ratingParticipantsSlice.reducer

export const ratingParticipantsSliceActions = {
  ...ratingParticipantsSlice.actions,
  getRatingTeams,
  createRatingTeams,
  unitingRatingTeams,
  unitingParticipantsFromOtherRatingTeams,
  deleteFromRatingTable,
  disbandRatingTeams,
}
