import { ChampionshipsParticipants } from '../api/model/models'
import { Team } from '../api/model/team'

export const transformEvalTeamsData = (teams: Team[]): RatingParticipantsTableData[] => {
  const transformTeamParticipants = (
    participants: ChampionshipsParticipants[],
    teamId: string | number,
  ): RatingParticipantsTableData[] => {
    const newParticipants: RatingParticipantsTableData[] = participants.map((participant) => ({
      id: `${participant.id}-${teamId}`,
      name: participant.fullName,
      organization: participant.company,
      region: participant.company,
      expert: participant.competenceRole === 'Эксперт',
      teamId,
    }))

    return newParticipants
  }

  const newTableData = teams.map((team) => {
    if (team.teamParticipants && team.teamParticipants?.length > 1) {
      const newTeam: RatingParticipantsTableData = {
        id: team.id,
        name: team?.teamName,
        organization: '',
        region: '',
        children: transformTeamParticipants(team.teamParticipants, team.id),
      }

      return newTeam
    }

    if (team.teamParticipants?.length === 1) {
      const newParticipants: RatingParticipantsTableData = {
        id: team.id,
        name: team.teamParticipants[0].fullName,
        organization: team.teamParticipants[0].company,
        region: team.teamParticipants[0].company,
        expert: team.teamParticipants[0].competenceRole === 'Эксперт',
      }

      return newParticipants
    }

    return undefined
  })

  const tableData = newTableData
    .filter((item) => !!item)
    .sort((a, b) => (a && a.children && b && !b.children ? -1 : 1)) as RatingParticipantsTableData[]

  return tableData
}
